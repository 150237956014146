import { AbstractApiModel } from './abstract-api-model';
import { InsightAuditEntry } from './insight-audit-entry';
import { Point } from './interfaces/point';
import { Point3d } from './interfaces/point3d';
import { Price } from './price';
import { ScanEventIssue } from './scan-event-issue';
import { ScanEventTag } from './scan-event-tag';
import { TopStock } from './top-stock';
import { ViewportImage } from './viewport-image';

import * as _ from 'lodash';

export class Viewport extends AbstractApiModel<Viewport> {
  gtin: string;
  expectedPrice: Price;
  saleExpectedPrice: Price;
  hasViewportIssue: boolean;
  inventoryActionable: string;
  onHandQuantity: number;
  realogramViewportGuid: string;
  clientModuleId: string;
  scanEventId: number;
  scanEventTagId: number;
  productId: number;
  productImageUrl: string;

  storeLocation: Point3d[];
  shelfLocation: Point[];
  viewportImages: ViewportImage[] = [];
  scanEventIssues: ScanEventIssue[] = [];
  tag: ScanEventTag;

  // Client only.
  sortOrdinal: number;
  auditComplete = false;
  auditEntries: InsightAuditEntry[];
  topStocks: TopStock[];

  hasImages(): boolean {
    return this.viewportImages.some(viewportImage => viewportImage.imageStatus === 'realized');
  }

  uploadStatus(): string {
    if (this.viewportImages.some(viewportImage => viewportImage.imageStatus === 'upload_failed') || this.tag.imageStatus === 'upload_failed' || this.tag.saleImageStatus === 'upload_failed') {
      return 'failed';
    }
    if (this.viewportImages.some(viewportImage => viewportImage.imageStatus === 'unrealized') || this.tag.imageStatus === 'unrealized' || this.tag.saleImageStatus === 'unrealized') {
      return 'fetching';
    }
    return 'completed';
  }

  deserialize(json: any): this {
    this.gtin = json.gtin;
    this.expectedPrice = Object.assign(new Price(), {
      price: (+json.expected_price).toFixed(2),
      quantity: json.expected_quantity,
      type: json.expected_type
    });
    this.saleExpectedPrice = Object.assign(new Price(), {
      price: (+json.sale_expected_price).toFixed(2),
      quantity: json.sale_expected_quantity,
      type: json.sale_expected_type
    });
    this.hasViewportIssue = json.has_viewport_issue;
    this.onHandQuantity = json.on_hand_quantity;
    this.realogramViewportGuid = json.realogram_viewport_guid;
    this.clientModuleId = json.client_module_id;
    this.scanEventId = json.scan_event_id;
    this.scanEventTagId = json.scan_event_tag_id;
    this.productId = json.product_id;
    this.productImageUrl = json.product_image_url;
    this.inventoryActionable = json.inventory_actionable == null ? 'true' : String(json.inventory_actionable);

    this.storeLocation = json.store_polygon;
    this.shelfLocation = json.shelf_polygon;
    if (json.image_urls) {
      json.image_urls.forEach((imageUrl) => {
        this.viewportImages.push(new ViewportImage().deserialize(imageUrl));
      });
    }

    this.tag = json.tag && new ScanEventTag().deserialize(json.tag);
    if (json.issues) {
      json.issues.forEach((issue) => {
        const scanEventIssue = new ScanEventIssue().deserialize(issue);
        scanEventIssue.viewport = this;
        scanEventIssue.scanEventId = this.scanEventId;
        this.scanEventIssues.push(scanEventIssue);
      });
    }

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {});
  }

  getImageUrl(imageLabel: string) {
    const viewportImage = this.viewportImages.find(image => image.imageLabel === imageLabel);

    return viewportImage ? viewportImage.imageUrl : null;
  }

  getViewportImage(imageLabel: string) {
    return this.viewportImages.find(image => image.imageLabel === imageLabel);
  }

  hasIssue(accepted: boolean = true): boolean {
    return _.some(this.scanEventIssues, issue => issue.isAcceptedIssue() === accepted);
  }

  hasIssueOfMetaType(type: string, accepted: boolean = true): boolean {
    return _.some(this.scanEventIssues, issue => issue.metaType === type && issue.isAcceptedIssue() === accepted);
  }

  hasIssueOfType(type: string, accepted: boolean = true): boolean {
    return _.some(this.scanEventIssues, issue => issue.issueType === type && issue.isAcceptedIssue() === accepted);
  }

  getIssueOfType(type: string): ScanEventIssue {
    return _.find(this.scanEventIssues, issue => issue.issueType === type);
  }

  getIssueOfMetaType(type: string): ScanEventIssue {
    return _.find(this.scanEventIssues, issue => issue.metaType === type);
  }

  getIssueOfGeneralizedType(type: string): ScanEventIssue {
    return _.find(this.scanEventIssues, issue => issue.getGeneralizedType() === type);
  }

  setTopStocks(topStocks: TopStock[]) {
    this.topStocks = topStocks;
  }

  hasTopStock(): boolean {
    return this.topStocks && this.topStocks.length > 0;
  }

  setAuditEntries(auditEntries: InsightAuditEntry[]) {
    this.auditEntries = auditEntries;
  }

  getAuditEntryOfType(issueType: string): InsightAuditEntry {
    return _.find(this.auditEntries, entry => entry.issueType == issueType);
  }

  auditEntryTypes(): any[] {
    return _.map(this.auditEntries, entry => entry.issueType);
  }

  hasAuditEntryOfType(issueType: string): boolean {
    return _.some(this.auditEntries, entry => entry.issueType == issueType);
  }

  checkForAuditComplete() {
    this.auditComplete = _.every(this.auditEntries, entry => !_.isNil(entry.rejected));
  }
}
