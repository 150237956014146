import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { Point, Point3d, Sku, Viewport } from '@ng-cloud/badger-core';
import { InsightImage } from '@ng-cloud/badger-core/models/interfaces/insight-image';

export class TopStock extends AbstractApiModel<TopStock> implements InsightImage {
  detectedGTIN: string;
  barcode: string;
  clientModuleId: string;
  productId: number;
  storeId: number;
  scanEventId: number;
  storeLocation: Point3d[];
  shelfLocation: Point[];
  imageLocation: Point[];
  imageUrl: string;
  imageStatus: string;
  skus: Sku[] = [];

  viewport: Viewport;

  deserialize(json: any): this {
    this.detectedGTIN = json.detected_gtin;
    this.clientModuleId = json.client_module_id;
    this.productId = json.product_id;
    this.barcode = json.barcode;
    this.storeId = json.store_id;
    this.scanEventId = json.scan_event_id;
    this.storeLocation = json.store_polygon;
    this.shelfLocation = json.shelf_polygon;
    this.imageLocation = json.image_polygon;
    this.imageUrl = json.image_file?.image_url;
    this.imageStatus = json.image_file?.status;
    if (json.skus) {
      json.skus.forEach((sku) => {
        this.skus.push(new Sku().deserialize(sku));
      });
    }
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      id: this.id,
      detected_gtin: this.detectedGTIN,
      client_module_id: this.clientModuleId,
      product_id: this.productId,
      store_id: this.storeId
    });
  }

  getImageUrl(): string {
    return this.imageUrl;
  }

  getObjectRects(): Point[][] {
    const objectRects: Point[][] = [];
    this.imageLocation && objectRects.push(this.imageLocation);

    return objectRects;
  }

  getImageStatus(): string {
    return this.imageStatus;
  }

  getObjectColor(): string {
    return '#c71bf1';
  }
}
