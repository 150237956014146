import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { Point } from './interfaces/point';
import { InsightImage } from './interfaces/insight-image';

export enum ViewportImagePosition {
  left = 'left',
  center = 'center',
  right = 'right'
}

export class ViewportImage extends AbstractApiModel<ViewportImage> implements InsightImage {
  imageUrl: string;
  imageLabel: string;
  imageStatus: string;
  viewportLocation: Point[];
  tagLocation: Point[];

  deserialize(json: any): this {
    this.imageLabel = json.image_label;
    this.imageUrl = json.image_url;
    this.imageStatus = json.status || 'realized';
    this.viewportLocation = json.viewport_polygon;
    this.tagLocation = json.tag_polygon;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {});
  }

  getImageUrl(): string {
    return this.imageUrl;
  }

  getObjectRects(): Point[][] {
    const objectRects: Point[][] = [];
    this.viewportLocation && objectRects.push(this.viewportLocation);
    this.tagLocation && objectRects.push(this.tagLocation);

    return objectRects;
  }

  getImageStatus(): string {
    return this.imageStatus;
  }

  getObjectColor(): string {
    return 'greenyellow';
  }
}
