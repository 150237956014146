import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtCommonModule } from '../common/bt-common.module';
import { InsightImageComponent } from './insight-image.component';
import { ViewportThumbSelectionComponent } from './viewport-thumb-selection/viewport-thumb-selection.component';

@NgModule({
  imports: [
    CommonModule,
    BtCommonModule
  ],
  declarations: [
    InsightImageComponent,
    ViewportThumbSelectionComponent
  ],
  exports: [
    InsightImageComponent,
    ViewportThumbSelectionComponent
  ]
})

export class BtViewportModule {
}
