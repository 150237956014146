import { fabric } from 'fabric';
import { MapObject } from './map-object';
import { Point } from '@ng-cloud/badger-core/models/interfaces/point';
import { TopStock } from '../../models/top-stock';

export class TopStockObject extends MapObject {
  static DEFAULT_STROKE_WIDTH = 3;

  static DEFAULT_COLOR = '#FFFFFF90';
  static HIGHLIGHTED_COLOR = '#C71BF1';

  strokeWidth = TopStockObject.DEFAULT_STROKE_WIDTH;

  defaultOptions: any = {
    selectable: false,
    evented: true,
    lockScalingFlip: true,
    fill: 'transparent',
    stroke: TopStockObject.DEFAULT_COLOR,
    opacity: 1,
    lockMovementX: true,
    lockMovementY: true,
    hasControls: false,
    hoverCursor: 'pointer',
    shadow: {
      blur: 5,
      color: 'rgba(0, 0, 0, 0.8)',
      offsetX: 1,
      offsetY: 1
    }
  };

  rect: fabric.Rect;

  constructor(public topStock: TopStock, private points: Point[], config?: any) {
    super(config);

    const scaleFactor = config.scaleFactor ? config.scaleFactor : 1;
    this.strokeWidth = TopStockObject.DEFAULT_STROKE_WIDTH * scaleFactor;

    Object.assign(this.defaultOptions, { strokeWidth: this.strokeWidth });
  }

  protected build(): fabric.Object {
    this.rect = new fabric.Rect(this.getOptions());
    this.rect.data = this.topStock;
    return this.rect;
  }

  protected getInstanceOptions(): any {
    return {
      width: Math.abs(this.points[0].x - this.points[1].x) - this.strokeWidth,
      height: Math.abs(this.points[0].y - this.points[2].y) - this.strokeWidth,
      left: this.points[0].x,
      top: this.points[2].y
    };
  }

  setHighlighted(highlighted: boolean) {
    this.getFabric().set({
      stroke: highlighted ? TopStockObject.HIGHLIGHTED_COLOR : TopStockObject.DEFAULT_COLOR,
      strokeWidth: this.strokeWidth,
    });
  }
}
